<template>
  <businessModelLog  :id="id"></businessModelLog>
</template>
<script>
  export default {

  data() {
    return {
      id: ""
    };
  },
  created() {
this.id = this.$route.query.templateId;
  }

};
</script>
